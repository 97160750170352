/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "styles/theme";
import styled from "@emotion/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Review } from "./review";
import { REVIEW_DATA } from "./review-data";
import { gaEvent } from "utils/ga-utils";

const Title = styled.p`
  color: ${theme.neutral900};
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
  margin: 140px 0;

  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin: 80px 20px;
  }
`;

const Reference = styled.div`
  display: flex;
  gap: 80px;
  margin-bottom: 160px;

  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  animation: loop linear infinite 20s;

  @media screen and (max-width: 800px) {
    gap: 35px;
    margin-bottom: 80px;
  }
`;

const GrayFrame = styled.div`
  background-color: ${theme.neutral100};
  padding: 60px 0;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    gap: 12px;
    padding: 60px 12px;
  }
`;

const ImageContainer = styled.img<{ height?: string; minHeight?: string }>`
  height: ${(props) => (props.height ? props.height : "40px")};

  @media screen and (max-width: 800px) {
    height: ${(props) => (props.minHeight ? props.minHeight : "25px")};
  }
`;

const ArrowContainer = styled.div`
  background-color: white;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 30px;
    height: 30px;
  }
`;

export default function Section2() {
  const [reviews, setReview] = useState<number[]>([0, 1]);

  const clickNext = () => {
    gaEvent("Main-고객사-오른쪽 화살표");
    const length = REVIEW_DATA.length;
    const first_idx = reviews[1];
    const second_idx = reviews[1] + 1 >= length ? 0 : reviews[1] + 1;
    setReview([first_idx, second_idx]);
  };

  const clickPrev = () => {
    gaEvent("Main-고객사-왼쪽 화살표");
    const length = REVIEW_DATA.length;
    const first_idx = reviews[0] - 1 < 0 ? length - 1 : reviews[0] - 1;
    const second_idx = reviews[0];
    setReview([first_idx, second_idx]);
  };

  const reference_list = [
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/idus.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/bagelcode.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/lunit-logo.svg",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/greeting.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/jabis.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/munch-factory.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/fastlane.png",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/futureplay.webp",
    "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/mashup-ventures.svg",
  ];
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Title>
        빠르게 성장하는 기업들은
        <br />
        Mile과 함께 변화된 근무환경을 경험하고 있습니다.
      </Title>
      <div
        css={css`
          overflow: hidden;
          width: 100%;
        `}
      >
        <Reference>
          {[...reference_list, ...reference_list, ...reference_list].map(
            (item, index) => {
              return <ImageContainer src={item} key={`${index}_0`} />;
            }
          )}
        </Reference>
      </div>
      <GrayFrame>
        <ArrowContainer onClick={clickPrev}>
          <PrevArrow />
        </ArrowContainer>
        <Review
          title={REVIEW_DATA[reviews[0]].title}
          logo={REVIEW_DATA[reviews[0]].logo}
          article={REVIEW_DATA[reviews[0]].article}
          name={REVIEW_DATA[reviews[0]].name}
          job={REVIEW_DATA[reviews[0]].job}
        />
        <div
          css={css`
            @media screen and (max-width: 800px) {
              display: none;
            }
          `}
        >
          <Review
            title={REVIEW_DATA[reviews[1]].title}
            logo={REVIEW_DATA[reviews[1]].logo}
            article={REVIEW_DATA[reviews[1]].article}
            name={REVIEW_DATA[reviews[1]].name}
            job={REVIEW_DATA[reviews[1]].job}
          />
        </div>
        <ArrowContainer onClick={clickNext}>
          <NextArrow />
        </ArrowContainer>
      </GrayFrame>
    </div>
  );
}

const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M16.6673 8.33337L28.334 20L16.6673 31.6667"
        stroke="#A0A8B0"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PrevArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M25.0007 8.33337L13.334 20L25.0007 31.6667"
        stroke="#A0A8B0"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
