/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "styles/theme";
import styled from "@emotion/styled";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { gaEvent } from "utils/ga-utils";
import FreeTrialModal from "uis/free-trial";

const Container = styled.div`
    padding-top: 130px;
    background: linear-gradient(95deg, #3885ff -2.44%, #46bdff 95.36%), #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
    overflow-x: hidden;

    @media screen and (max-width: 800px) {
        padding-top: 50px;
        padding-bottom: 30px;
    }
`;

const Title = styled.h1`
    color: white;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: 130%;
    margin-top: 128px;

    @media screen and (max-width: 800px) {
        font-size: 30px;
    }
`;

const SubTitle = styled.p`
    color: ${theme.neutral50};
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 130%;
    margin-top: 40px;

    strong {
        font-weight: 800;
    }

    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

const Btn = styled.button`
    border-radius: 8px;
    background: white;
    box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.24);
    width: 170px;
    height: 56px;
    display: flex;
    align-items: center;
    border: none;
    margin: 40px auto 50px auto;
    justify-content: center;

    color: ${theme.primary500};
    font-size: 24px;
    font-weight: 700;
    position: relative;

    cursor: pointer;

    @media screen and (max-width: 800px) {
        font-size: 16px;
        width: 120px;
        height: 42px;
    }
`;

const SlideImage = styled.div`
    width: 1201px;
    height: 812px;
    margin: 0 auto 80px auto;

    @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
    }
`;

export default function Section1() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    return (
        <Container>
            <Title>
                기업내 공간 관리
                <br />
                데이터로 최적화하세요.
            </Title>
            <SubTitle>
                <strong>Mile</strong>은 데이터를 통해 오피스 내 공간을
                <br />
                가장 효율적으로 사용할 수 있도록 돕습니다.
            </SubTitle>
            <Btn
                onClick={() => {
                    setOpenModal(true);
                    gaEvent("Main-첫화면-무료 체험하기");
                }}
            >
                무료 체험하기
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 8px;
                        width: 170px;
                        height: 56px;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                        }
                        @media screen and (max-width: 800px) {
                            width: 120px;
                            height: 42px;
                        }
                    `}
                />
            </Btn>
            <div
                css={css`
                    position: relative;
                    bottom: -40px;
                `}
            >
                <Union />
            </div>
            <Carousel infiniteLoop autoPlay interval={4000} showThumbs={false} showArrows={false} swipeable={false}>
                <SlideImage>
                    <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/section1/slide1.png" />
                </SlideImage>
                <SlideImage>
                    <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/section1/slide2.png" />
                </SlideImage>
            </Carousel>
            <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
        </Container>
    );
}

const Union = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="62" viewBox="0 0 12 62" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6 12H7L7 61.6155H5L5 12H6Z"
                fill="white"
            />
        </svg>
    );
};
