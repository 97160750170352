/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "styles/theme";
import Logo from "uis/component/logo";
import CheckIcon from "uis/Icon/CheckIcon";
import Footer from "uis/component/footer";
import Form, { FormContainer } from "./form";

import {
  Annotation,
  CheckContainer,
  GridContainer,
  Header,
  InputContainer,
  IntroContainer,
  Line,
  Link,
  MainContent,
  ReferenceContainer,
  References,
  RootContainer,
  Title,
} from "./styles";
import axios from "axios";
import LoadingModal from "uis/loading-modal";
import { gaEvent } from "utils/ga-utils";
import UI from "@mile-meeting/mile-ui";

export function isValidPhoneNumber(number: string) {
  const regex = /^\d{9,11}$/;
  return regex.test(number);
}

export const isValidEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export default function ContactSales() {
  const [name, setName] = useState<string>();
  const [company, setCompanay] = useState<string>();
  const [job, setJob] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [tel, setTel] = useState<string>();
  const [content, setContent] = useState<string>();
  const [agree, setAgree] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [meeting, setMeeting] = useState<boolean>(false);
  const [visitors, setVisitors] = useState<boolean>(false);

  const onSubmit = async () => {
    gaEvent("도입 문의-Mile 안내 받기");
    setLoading(true);
    if (
      name &&
      company &&
      email &&
      isValidEmail(email) &&
      tel &&
      isValidPhoneNumber(tel) &&
      agree &&
      (meeting || visitors)
    ) {
      try {
        const res = await axios.post("https://alb-tablet.mile.im/users/email-archiving/contact-sales", {
          name: name,
          companyName: company,
          position: job,
          email: email,
          phoneNumber: tel,
          memo: `[제품 선택: ${meeting && "meeting"} ${visitors && "visitors"}] ` + content,
        });
        if (res.data.result) {
          alert("문의가 완료되었습니다.");
          window.location.reload();
        }
      } catch (error) {
        alert("다시 시도해주세요.");
      }
    } else if (name && company && email && isValidEmail(email) && tel && isValidPhoneNumber(tel) && !agree) {
      alert("동의항목에 체크해주세요.");
    } else {
      alert("아직 작성되지 않은 필드가 있습니다.");
    }
    setLoading(false);
  };

  return (
    <>
      <Header>
        <Logo />
      </Header>
      <RootContainer>
        <GridContainer>
          <IntroContainer className="item">
            <Title>도입 문의</Title>
            <MainContent>Mile이 제공해 드리는{"\n"}가치를 알아보세요!</MainContent>
            <Annotation>
              몇 가지 정보를 입력해 주시면{"\n"}여러분의 회사에 적합한 Mile 도입 안내를 보내드립니다.
            </Annotation>
            <CheckContainer>
              <div>
                <CheckIcon color={theme.neutral400} width={24} height={24} />
                <p>기업 현황에 따른 맞춤형 안내</p>
              </div>
              <div>
                <CheckIcon color={theme.neutral400} width={24} height={24} />
                <p>견적 및 비용 안내</p>
              </div>
              <div>
                <CheckIcon color={theme.neutral400} width={24} height={24} />
                <p>우리 회사와 비슷한 기업의 실제 사용 사례</p>
              </div>
            </CheckContainer>
          </IntroContainer>
          <InputContainer className="item">
            <Form
              label="성함"
              isEssential={true}
              placeholder="이름"
              onChange={(e) => typeof e === "string" && setName(e)}
            />
            <div
              css={css`
                display: flex;
                gap: 20px;

                @media screen and (max-width: 780px) {
                  flex-direction: column;
                  gap: 40px;
                }
              `}
            >
              <Form
                label="회사명"
                isEssential={true}
                placeholder="회사명"
                onChange={(e) => typeof e === "string" && setCompanay(e)}
              />
              <Form
                label="직책"
                placeholder="담당하고 계시는 직책"
                onChange={(e) => typeof e === "string" && setJob(e)}
              />
            </div>
            <Form
              label="이메일"
              isEssential={true}
              placeholder="ex) mile@mile.im"
              onChange={(e) => typeof e === "string" && setEmail(e)}
              error={email && !isValidEmail(email) ? "이메일 형식이 올바르지 않습니다." : ""}
            />
            <Form
              label="전화번호"
              isEssential={true}
              placeholder="ex) 01012345678"
              onChange={(e) => typeof e === "string" && setTel(e)}
              error={tel && !isValidPhoneNumber(tel) ? "전화번호 형식이 올바르지 않습니다." : ""}
            />
            <FormContainer>
              <p>
                도입 안내가 필요한 Mile 제품<span>*</span>
              </p>
              <div
                css={css`
                  display: flex;
                  gap: 20px;
                  margin-top: 8px;

                  @media screen and (max-width: 778px) {
                    flex-direction: column;
                  }
                `}
              >
                <div
                  onClick={() => setMeeting(!meeting)}
                  css={css`
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    cursor: pointer;
                  `}
                >
                  <UI.CheckBox selected={meeting} /> <UI.Typography type="body01 normal">Mile Meeting</UI.Typography>
                </div>
                <div
                  onClick={() => setVisitors(!visitors)}
                  css={css`
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    cursor: pointer;
                  `}
                >
                  <UI.CheckBox selected={visitors} /> <UI.Typography type="body01 normal">Mile Visitors</UI.Typography>
                </div>
              </div>
            </FormContainer>

            <Form
              onChange={(e) => typeof e === "string" && setContent(e)}
              multi={true}
              label="문의사항"
              placeholder="도입 배경, 필수 요건, 옵션 요건, 기존 사용 솔루션이 있으시다면 기재해 주세요. 회사 규모, 회의실 수 등을 입력해 주시면 더욱 상세한 안내가 가능합니다."
            />
            <div
              css={css`
                display: flex;
                gap: 12px;
              `}
            >
              <UI.CheckBox selected={agree} onChange={(isChecked) => setAgree(isChecked)} />
              <Annotation>
                <span
                  onClick={() => window.open("https://policy.mile.im/privacy/2024.06.19.html")}
                  css={css`
                    cursor: pointer;
                    text-decoration-line: underline;
                    font-weight: 600;
                  `}
                >
                  개인정보 수집 및 이용
                </span>
                에 동의합니다.
              </Annotation>
            </div>
            <UI.Button onClick={onSubmit} title="Mile 안내 받기" size="large" />
          </InputContainer>
          <ReferenceContainer className="item">
            <Annotation>이미 수많은 기업들이 Mile과 함께 업무 환경을 변화시키고 있습니다.</Annotation>
            <References>
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/idus.png" />
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/lunit-logo.svg" />
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/greeting.png" />
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png" />
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/jabis.png" />
              <img src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/fastlane.png" />
            </References>
            <Line />
            <Annotation>답변을 기다리시는 동안 마일이 궁금하시다면</Annotation>
            <Link
              onClick={() => {
                window.open("https://www.mile.im/product/tutorial");
                gaEvent("도입 문의-Mile 서비스 체험하기");
              }}
            >
              Mile 서비스 체험하기
            </Link>
          </ReferenceContainer>
        </GridContainer>
      </RootContainer>
      <LoadingModal modalIsOpen={loading} />
      <Footer />
    </>
  );
}
