/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";

const Container = styled.div`
    padding: 200px 137px 142px 170px;
    width: 1133px;
    display: flex;
    flex-direction: column;
    gap: 160px;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        gap: 80px;
        width: 100%;
        padding: 24px 0;
    }
`;

const ArticleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 800px) {
        gap: 12px;
    }

    h3 {
        color: ${theme.neutral900};
        font-size: 40px;
        font-weight: 700;
        line-height: 120%; /* 48px */
        letter-spacing: -0.4px;

        @media screen and (max-width: 800px) {
            font-size: 24px;
        }
    }

    p {
        color: ${theme.neutral600};
        font-size: 18px;
        font-weight: 500;
        line-height: 150%; /* 30px */

        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin-bottom: 24px;
        }
    }
`;

const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        margin: 0 12px;
        width: calc(100% - 24px);
    }
`;

export default function Section2() {
    return (
        <Container>
            <SectionContainer
                css={css`
                    @media screen and (max-width: 800px) {
                        flex-direction: column-reverse;
                    }
                `}
            >
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img1.png"
                    css={css`
                        width: 697px;
                        height: 815.5px;
                        position: absolute;
                        top: 0;
                        left: 0;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
                <ArticleContainer
                    css={css`
                        margin-left: auto;
                        margin-right: 33px;
                        margin-top: 138px;
                        margin-bottom: 134px;

                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <h3>예약 현황 조회</h3>
                    <div>
                        <p>
                            회의실 내에서 간편하게 예약 현황을 파악하고
                            <br />
                            현재 회의실 사용 상태를 확인할 수 있습니다.
                        </p>
                        <p
                            css={css`
                                margin-top: 12px;
                            `}
                        >
                            구글 캘린더 등록 회의들도 Mile Meeting과
                            <br />
                            연동되어 표시됩니다.
                        </p>
                    </div>
                </ArticleContainer>
            </SectionContainer>
            <SectionContainer>
                <ArticleContainer
                    css={css`
                        margin: 176px 181px 176px 0;

                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <h3>회의실 예약</h3>
                    <div>
                        <p>
                            회의가 필요할 때 빈 회의실에서
                            <br />
                            바로 예약을 통해 사용할 수 있습니다.
                        </p>
                        <p
                            css={css`
                                margin-top: 12px;
                            `}
                        >
                            회의 중에도 다음 회의를 간편하게
                            <br />
                            예약할 수 있습니다.
                        </p>
                    </div>
                </ArticleContainer>
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img2.png"
                    css={css`
                        width: 630px;
                        height: 560px;
                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
            </SectionContainer>
            <SectionContainer
                css={css`
                    @media screen and (max-width: 800px) {
                        flex-direction: column-reverse;
                    }
                `}
            >
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/img3.png"
                    css={css`
                        width: 680px;
                        height: 516px;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
                <ArticleContainer
                    css={css`
                        margin: 157px 0 121px 60px;
                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <h3>회의 시간 연장</h3>
                    <div>
                        <p>
                            회의 시간이 부족하여 시간이 더 필요하신가요?
                            <br />
                            태블릿 내에서 간편하게 회의 시간을 연장해보세요.
                        </p>
                        <p
                            css={css`
                                margin-top: 12px;
                            `}
                        >
                            바로 뒤에 예약된 회의가 있다면,
                            <br />
                            다른 회의실 예약 혹은 뒷 타임 담당자에게
                            <br />
                            회의 연장 요청을 보낼 수 있습니다.
                        </p>
                    </div>
                </ArticleContainer>
            </SectionContainer>
            <SectionContainer>
                <ArticleContainer
                    css={css`
                        margin: 127px 134px 216px 0;

                        @media screen and (max-width: 800px) {
                            margin: 0;
                        }
                    `}
                >
                    <h3>회의실 데이터 활용</h3>
                    <div>
                        <p>
                            회의실에서 발생하는 데이터를 한눈에 확인해보시고,
                            <br />
                            이를 반영하여 관련된 정책을 세워보세요.
                        </p>
                        <p
                            css={css`
                                margin-top: 12px;
                            `}
                        >
                            회의와 관련된 인사이트를 시각화해서 제공해드려요.
                        </p>
                    </div>
                </ArticleContainer>
                <img
                    src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/image4.png"
                    css={css`
                        width: 602px;
                        height: 535px;

                        @media screen and (max-width: 800px) {
                            position: relative;
                            width: 100%;
                            height: max-content;
                            max-width: 450px;
                        }
                    `}
                />
            </SectionContainer>
        </Container>
    );
}
