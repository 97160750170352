/** @jsxImportSource @emotion/react */

import Inquire from "../inquire";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import { css } from "@emotion/react";

export default function Meeting() {
    return (
        <>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <div
                css={css`
                    padding: 120px 0 160px 0;

                    @media screen and (max-width: 800px) {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 40px;
                    }
                `}
            >
                <Inquire ga="Meeting-고민완화-무료 체험하기" />
            </div>
        </>
    );
}
