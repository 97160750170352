/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { theme } from "styles/theme";
import CloseIcon from "uis/Icon/CloseIcon";
import RefreshIcon from "uis/Icon/RefreshIcon";
import Button from "uis/button";
import InputField from "uis/inputField";

const Container = styled.div`
    display: flex;
    padding: 60px 40px 40px 40px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background-color: white;
    border-radius: 30px;
    position: relative;
    width: 400px;

    box-shadow: 0px 4px 26px 0px rgba(69, 76, 83, 0.08);
`;
const Title = styled.p`
    color: ${theme.neutral900};
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.24px;
    white-space: pre-line;

    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

export const isValidEmail = (email: string) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function EmailModal({
    isShow = false,
    product = "visitors",
    onSubmit,
}: {
    isShow?: boolean;
    product?: "visitors" | "meeting";
    onSubmit?: (email: string) => void;
}) {
    const [email, setEmail] = useState<string>("");
    const url = useLocation();
    const [loading, setLoading] = useState<boolean>(false);

    const getErrorMessage = (email: string): { ok: boolean; error: string } => {
        if (email === "") return { ok: false, error: "" };
        else if (isValidEmail(email)) {
            return { ok: true, error: "" };
            // const domain = email.split("@")[1].trim();

            // if (["gmail.com", "naver.com", "outlook.com"].includes(domain)) {
            //     return { ok: false, error: "업무용 이메일을 입력해주세요." };
            // } else {
            //     return { ok: true, error: "" };
            // }
        } else return { ok: false, error: "올바르지 않은 이메일 형식입니다." };
    };

    if (isShow)
        return (
            <div
                css={css`
                    border-radius: 30px;
                    background: rgba(0, 0, 0, 0.6);
                    backdrop-filter: blur(15px);
                    width: 1100px;
                    height: 600px;
                    position: absolute;
                    top: 0;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        right: 24px;
                        top: 24px;
                    `}
                >
                    <CloseIcon width={28} height={28} />
                </div>
                <Container>
                    <MileLogo />
                    <Title>남은 기능들도 모두 확인해보세요!</Title>
                    <InputField
                        errorText={getErrorMessage(email).error}
                        type={getErrorMessage(email).error === "" ? "default" : "error"}
                        onChange={setEmail}
                        placeholder="업무용 이메일을 입력해주세요."
                        styles={{
                            input: css`
                                padding: 18px 16px;
                            `,
                        }}
                    />

                    <Button
                        leftIcon={({ color }) => (loading ? <RefreshIcon color={color} /> : <></>)}
                        onClick={async () => {
                            if (!loading) {
                                if (getErrorMessage(email).ok) {
                                    setLoading(true);
                                    const res = await sendEmail(email, product, "/" + url.search);
                                    if (res) {
                                        onSubmit?.(email);
                                    }
                                    setLoading(false);
                                } else {
                                    alert("이메일을 입력해주세요.");
                                }
                            }
                        }}
                        title={loading ? "" : "이어서 진행하기"}
                        styles={{
                            container: css`
                                padding: 16px 0;
                                background-color: ${loading ? theme.neutral100 : theme.primary500};
                                &:hover {
                                    background-color: ${loading ? theme.neutral100 : "#1065ED"};
                                }
                            `,
                        }}
                    />
                    <p
                        css={css`
                            font-size: 12px;
                            color: ${theme.neutral400};
                            a {
                                color: ${theme.primary300};
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        `}
                    >
                        등록하면 <a href="https://policy.mile.im/privacy/2024.06.19.html">개인정보 보호정책</a>에 설명된 대로 mile이
                        개인데이터를 처리하는데 동의하는 것으로 간주됩니다.
                    </p>
                </Container>
            </div>
        );
    else return <></>;
}

const MileLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <rect width="63.8599" height="63.8599" rx="12.0101" fill="#3885FF" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.6331 20.0238H27.6149C23.4894 20.0238 20.145 23.3621 20.145 27.48V43.1381C20.145 47.256 23.4894 50.5943 27.6149 50.5943H43.3018C44.7147 50.5943 46.036 50.2027 47.1628 49.5223L44.1739 45.9915C43.898 46.0754 43.6052 46.1205 43.3018 46.1205H27.6149C25.9647 46.1205 24.627 44.7852 24.627 43.1381V27.48C24.627 25.8328 25.9647 24.4975 27.6149 24.4975H38.6331V20.0238ZM43.3018 24.4975C44.952 24.4975 46.2897 25.8328 46.2897 27.48V43.1381C46.2897 43.3607 46.2653 43.5776 46.219 43.7863L49.3616 47.4988C50.2488 46.2725 50.7717 44.7662 50.7717 43.1381V27.48C50.7717 23.3621 47.4273 20.0238 43.3018 20.0238V24.4975Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.3317 17.7872C20.0283 17.7872 19.7355 17.8323 19.4596 17.9163L16.4707 14.3854C17.5974 13.7051 18.9188 13.3135 20.3317 13.3135H36.0186C40.1441 13.3135 43.4885 16.6517 43.4885 20.7697V36.4277C43.4885 40.5457 40.1441 43.8839 36.0186 43.8839H24.4402V39.4102H36.0186C37.6688 39.4102 39.0065 38.0749 39.0065 36.4277V20.7697C39.0065 19.1225 37.6688 17.7872 36.0186 17.7872H20.3317ZM19.7715 39.3579C18.3891 39.0961 17.3438 37.8838 17.3438 36.4277V20.7697C17.3438 20.5471 17.3682 20.3302 17.4145 20.1215L14.2719 16.409C13.3846 17.6352 12.8618 19.1415 12.8618 20.7697V36.4277C12.8618 40.3576 15.9076 43.5773 19.7715 43.8633V39.3579Z"
                fill="white"
            />
        </svg>
    );
};

export const sendEmail = async (email: string, product: "visitors" | "meeting", url: string): Promise<boolean> => {
    try {
        const res = await axios.post("https://alb-tablet.mile.im/users/email-archiving/demo-modal/v2", {
            email: email,
            product: product,
            url: url,
        });
        return res.data.result;
    } catch (error) {
        console.error(error);
        return false;
    }
};
