/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useCallback, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { theme } from "styles/theme";
import Logo from "./logo";
import { gaEvent } from "utils/ga-utils";
import Button from "uis/button";

const PageLabel = styled.p<{ highlight?: boolean }>`
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;

  text-decoration: ${(props) =>
    props.highlight ? `underline 3px ${theme.primary500}` : null};
  text-underline-offset: ${(props) => (props.highlight ? "8px" : null)};

  &:hover {
    text-decoration: underline 3px ${theme.primary500};
    text-underline-offset: 8px;
  }
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const Btn = styled.p`
  padding: 0 14px;
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${theme.neutral50};
  color: ${theme.neutral500};
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;

  &:hover {
    background-color: ${theme.neutral100};
  }

  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const BtnText = styled.p`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 40px;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  width: calc(100% - 80px);
  padding: 0 40px;
  background-color: white;

  @media screen and (max-width: 800px) {
    width: calc(100% - 48px);
    padding: 0 24px;
  }
`;

const Banner = styled.div`
  background-color: ${theme.primary500};
  height: 60px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export default function Header() {
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const [showBorder, setShowBorder] = useState<boolean>(false);

  window.addEventListener("scroll", (e) => {
    if (window.scrollY > 100) {
      setShowBorder(true);
    } else {
      setShowBorder(false);
    }
  });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        z-index: 5;
        width: 100%;

        justify-content: center;

        border-bottom: ${showBorder ? "1px #eef1f3 solid" : "1px white solid"};
        transition: border 0.3s ease;
      `}
    >
      <Banner>
        🤭 마일이 궁금하신가요? 우리 회사에 딱 맞는 제안서 받아보세요! →
        <a
          onClick={() => {
            window.open("https://www.mile.im/proposal-sales");
            gaEvent("banner-도입 제안서");
          }}
          href=""
          css={css`
            color: white;
            font-weight: 700;
            margin-left: 8px;
            cursor: pointer;
          `}
        >
          제안서 받아보기
        </a>
      </Banner>
      <HeaderContainer>
        <Logo />
        <div
          css={css`
            display: flex;
            gap: 40px;
            align-items: center;
            margin-left: 50px;
            @media screen and (max-width: 800px) {
              margin-left: auto;
              margin-right: 12px;
              gap: 12px;
            }
          `}
        >
          <LabelSelect
            render={() => (
              <PageLabel highlight={page === "product"}>서비스 소개</PageLabel>
            )}
            subPages={[
              {
                value: "Mile Meeting",
                url: "/product/meeting",
                event: "header-서비스 소개-Mile Meeting",
              },
              {
                value: "Mile Visitors",
                url: "/product/visitors",
                event: "header-서비스 소개-Mile Visitors",
              },
              {
                value: "Mile Analytics",
                url: "/product/analytics",
                event: "header-서비스 소개-Mile Analytics",
              },
              {
                value: "Mile 서비스 체험하기",
                url: "/product/tutorial",
                event: "header-서비스 소개-Mile 서비스 체험하기",
              },
            ]}
          />

          <PageLabel
            onClick={() => {
              window.open("https://blog.mile.im/category/고객사례");
              gaEvent("header-고객사례");
            }}
            css={css`
              @media screen and (max-width: 800px) {
                display: none;
              }
            `}
          >
            고객사례
          </PageLabel>
          <PageLabel
            onClick={() => {
              gaEvent("header-제품소개서");
              window.location.href = "https://relate.so/doc/vqhj6p94udaknglg ";
            }}
            css={css`
              @media screen and (min-width: 800px) {
                display: none;
              }
            `}
          >
            제품 소개서
          </PageLabel>
        </div>
        <div
          css={css`
            display: flex;
            margin-left: auto;

            @media screen and (max-width: 800px) {
              margin-left: 0;
            }
          `}
        >
          <BtnText
            onClick={() => {
              gaEvent("header-서비스 체험하기");
              window.location.href = "/product/tutorial";
            }}
            css={css`
              @media screen and (max-width: 800px) {
                display: none;
              }
            `}
          >
            서비스 체험하기
          </BtnText>
          <BtnText
            onClick={() => {
              gaEvent("header-제품소개서");
              window.location.href = "https://relate.so/doc/vqhj6p94udaknglg";
            }}
            css={css`
              @media screen and (max-width: 800px) {
                display: none;
              }
            `}
          >
            제품 소개서
          </BtnText>

          <LabelSelect
            windowOpen={true}
            render={() => <BtnText>사용자 가이드</BtnText>}
            subPages={[
              {
                value: "마일 사용 가이드",
                url: "https://mile.mintlify.app/introduction",
                event: "header-사용자가이드-마일 사용 가이드",
              },
              {
                value: "릴리즈 노트",
                url: "https://mile.mintlify.app/notice/update",
                event: "header-사용자가이드-릴리즈 노트",
              },
            ]}
          />
          <LabelSelect
            windowOpen={true}
            render={() => (
              <Btn
                css={css`
                  @media screen and (min-width: 800px) {
                    display: none;
                  }
                `}
              >
                더보기
              </Btn>
            )}
            subPages={[
              {
                value: "문의하기",
                url: "https://www.mile.im/contact-sales",
                event: "header-문의하기",
              },
              {
                value: "고객사례",
                url: "https://blog.mile.im/category/%EA%B3%A0%EA%B0%9D%EC%82%AC%EB%A1%80",
                event: "header-고객사례",
              },
              {
                value: "사용자 가이드",
                url: "https://mile.mintlify.app/introductio",
                event: "header-사용자가이드-마일 사용 가이드",
              },
              {
                value: "릴리즈 노트",
                url: "https://mile.mintlify.app/notice/update",
                event: "header-사용자가이드-릴리즈 노트",
              },
            ]}
          />
        </div>
        <BtnText
          css={css`
            border-left: ${theme.neutral100} 2px solid;
            padding: 0 0 0 20px;
          `}
          onClick={() => {
            gaEvent("header-로그인");
            window.open("https://app.mile.im");
          }}
        >
          로그인
        </BtnText>
        <Button
          onClick={() => {
            gaEvent("header-문의하기");
            window.location.href = "/contact-sales";
          }}
          title="도입 문의하기"
          styles={{
            container: css`
              padding: 12px 16px;
              @media screen and (max-width: 800px) {
                display: none;
              }
            `,
          }}
        />
      </HeaderContainer>
    </div>
  );
}

const SelectLabel = styled.a`
  color: ${theme.neutral900};
  font-size: 18px;
  font-weight: 500;
  padding: 16px 12px;
  min-width: 200px;
  border-radius: 6px;

  text-decoration: none;

  &:hover {
    color: ${theme.primary500};
    background-color: ${theme.neutral50};
    font-weight: 700;
  }
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    min-width: 150px;
  }
`;

function LabelSelect({
  title,
  subPages,
  render,
  windowOpen = false,
}: {
  title?: string;
  subPages?: { value?: string; url?: string; event?: string }[];
  render?: () => JSX.Element;
  windowOpen?: boolean;
}) {
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);
  const selectRef = useRef<HTMLObjectElement>(null);

  const closeSelect = useCallback((e: any) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpenSelect(false);
    }
  }, []);

  window.addEventListener("click", (e) => {
    window.addEventListener("click", closeSelect);
  });

  return (
    <div
      ref={selectRef}
      onClick={() => setIsOpenSelect(true)}
      css={css`
        position: relative;
      `}
    >
      {!render ? <PageLabel>{title}</PageLabel> : render()}

      {subPages && isOpenSelect && (
        <div
          css={css`
            position: absolute;
            border-radius: 10px;
            background: white;
            box-shadow: 0px 4px 20px 0px rgba(69, 76, 83, 0.1);
            padding: 16px 12px;
            white-space: nowrap;
            top: 140%;
            display: flex;
            flex-direction: column;

            /* left: -10px; */

            @media screen and (max-width: 800px) {
              font-size: 14px;
              left: -100%;
              right: 0;
              width: max-content;
            }
          `}
        >
          {subPages?.map((item, index) => {
            return (
              <SelectLabel
                key={index}
                href={`${item.url}`}
                onClick={() => {
                  if (item.event) gaEvent(item.event);
                }}
              >
                {item.value}
              </SelectLabel>
            );
          })}
        </div>
      )}
    </div>
  );
}
