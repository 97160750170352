import Visitors from "./visitors";
import Header from "uis/component/header";
import Footer from "uis/component/footer";
import Meeting from "./meeting";
import Analytics from "./analytics";
import { Helmet } from "react-helmet-async";
import FreeTrialBtn from "uis/component/free-trial-btn";
import Tutorial from "./tutorial";

const getPage = (page: string): JSX.Element => {
  switch (page) {
    case "visitors":
      return <Visitors />;
    case "meeting":
      return <Meeting />;
    case "tutorial":
      return <Tutorial />;
    default:
      return <Analytics />;
  }
};

const getSEOInfo = (page: string) => {
  switch (page) {
    case "visitors":
      return {
        title: "기업용 방문자 관리 솔루션 - Mile Visitors",
        description:
          "Mile Visitors는 기업 내부 보안을 강화하면서 동시에 세련된 방문객 경험을 제공합니다. 클라이언트 미팅, 면접 등 모든 비즈니스 활동에 도움이 되는 효율적이고 안전한 방문자 관리 솔루션을 경험해보세요!",
        keywords:
          "mile, 마일, visitors, 비지터, 방문자 관리, mile 서비스, mile 솔루션, 방문자 관리 솔루션, 방문자 관리 서비스, 방문객 관리, 출입 관리, 외부인 관리, 공간 관리, 협업 솔루션, 협업 플랫폼, 일정 예약, 일정 관리, 구독 서비스, 구독형 방문자 관리 서비스, 스마트 오피스, 키오스크, 태블릿, 업무 관리, 공간 최적화, 구글 캘릭터, 캘린더 예약, 슬랙, SaaS, slack, google calendar",
      };
    case "meeting":
      return {
        title: "회의실 예약 관리 솔루션 - Mile Meeting",
        description:
          "Mile Meeting은 효율적인 회의실 예약 솔루션으로, 공간을 예약하고 관리하는 간편한 방법을 제공합니다. 사용하지 않는 회의실 공간을 최적화하여 기업 내 유후 공간을 효율적으로 최적화하고 활용할 수 있습니다.",
        keywords:
          "mile, 마일, meeting room, 회의실, 회의실 예약, 회의실 관리, 회의실 예약관리, 회의실 대여, 회의실 부족, 회의실 대관, 서울 회의실, 강남 회의실, 강남 회의실 대관, 공간 관리, 공간 예약, 사무실 예약, 협업 툴, 협업 솔루션, 협업 플랫폼, 일정 예약, 일정 관리, 구독 서비스, 구독형 회의실 예약 서비스, 스마트 오피스, 키오스크, 태블릿, 회의실 태블릿, 업무 관리, 공간 최적화, 캘린더 예약, 구글 캘린더, 슬랙, SaaS, slack, google calendar",
      };
    case "analytics": {
      return {
        title: "공간 데이터 통합 분석 솔루션 - Mile Analytics",
        description:
          "Mile Analytics로 기존에 휘발되던 공간 차원의 데이터를 모아 보고, 도입 이후의 운영 과정에 지속적으로 활용 가능한 비즈니스 인사이트를 경험해보세요. 더 나은 결정과 효율적인 공간 관리를 위한 새로운 인사이트를 제공합니다!",
        keywords:
          "mile, 마일, analytics, 애널리틱스, 대시보드, mile 서비스, mile 솔루션, 공간 데이터, 데이터, 공간 관리, 협업 솔루션, 협업 플랫폼, 일정 예약, 일정 관리, 구독 서비스, 구독형 데이터 서비스, 스마트 오피스, 키오스크,태블릿, 업무 관리, 공간 최적화, 구글 캘린더, 슬랙, 캘린더 예약, SaaS, slack, google calendar",
      };
    }
  }
};
export default function Product({ page }: { page: string }) {
  return (
    <div>
      <Helmet>
        <title>{getSEOInfo(`${page}`)?.title}</title>
        <meta name="description" content={getSEOInfo(`${page}`)?.description} />
        <meta name="keywords" content={getSEOInfo(`${page}`)?.keywords} />
      </Helmet>
      <Header />

      {page && getPage(page)}

      <Footer />
      <FreeTrialBtn />
    </div>
  );
}
