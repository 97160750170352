import axios from "axios";

export const sendVisitGuide = async (
  guideChannel?: string,
  phoneNumber?: string,
  email?: string,
  acceptTerm?: boolean
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/landing/visitors/visit-guide`,
      {
        guideChannel: guideChannel?.toLocaleUpperCase(),
        phoneNumber: phoneNumber,
        email: email,
        acceptTerm: acceptTerm,
      }
    );

    return res.data.result;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const sendIntroductionProposal = async (
  name: string,
  email: string,
  meeting: boolean,
  meetingProblem?: string,
  visitors?: boolean,
  visitorsProblem?: string,
  acceptTerm?: boolean
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/landing/introduction-proposal`,
      {
        name: name,
        email: email,
        meeting: meeting,
        meetingProblem: meetingProblem,
        visitors: visitors,
        visitorsProblem: visitorsProblem,
        acceptTerm: acceptTerm,
      }
    );

    return res.data.result;
  } catch (error) {
    console.error(error);
    return false;
  }
};
