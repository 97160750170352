/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { theme } from "styles/theme";
import Icon from "uis/Icon";
import Logo from "./logo";
import ChannelTalkModal from "./channel-talk-modal";
import { gaEvent } from "utils/ga-utils";

const Annotation = styled.p`
  color: ${theme.neutral500};
  text-align: center;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    color: ${theme.neutral600};
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    font-size: 8px;
  }
`;

const SectionDivider = styled.div`
  width: 1px;
  height: 10px;
  background-color: #d9d9d9;
`;

export default function Footer() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const device = window.screen.width > 500 ? "web" : "mobile";

  return (
    <div
      css={css`
        background-color: white;
      `}
    >
      <div
        css={css`
          max-width: 1400px;
          padding: 40px 126px 50px 126px;
          margin: 0 auto;

          @media screen and (max-width: 800px) {
            padding: 40px 24px 50px 24px;
          }
        `}
      >
        <div
          css={css`
            padding: 12px 0;
            position: relative;
            left: -12px;
          `}
        >
          <Logo color={theme.neutral300} />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 10px;
              align-items: center;

              @media screen and (max-width: 800px) {
                gap: 5px;
              }
            `}
          >
            <Annotation
              onClick={() => {
                gaEvent("footer-개인정보처리방침");
                window.open("https://policy.mile.im/privacy/2024.06.19.html");
              }}
            >
              개인 정보 처리 방침
            </Annotation>
            <SectionDivider />
            <Annotation
              onClick={() => {
                gaEvent("footer-서비스이용약관");
                window.open("https://policy.mile.im/service.html");
              }}
            >
              서비스 이용 약관
            </Annotation>
            <SectionDivider />
            <Annotation
              onClick={() => {
                gaEvent("footer-구글사용자이용정책");
                window.open(
                  "https://policy.mile.im/google-api-disclosure.html"
                );
              }}
            >
              구글 사용자 이용 정책
            </Annotation>
            <SectionDivider />
            <Annotation onClick={() => setIsOpen(true)}>문의하기</Annotation>
          </div>
          <div
            css={css`
              display: flex;
              gap: 10px;
              align-items: center;

              @media screen and (max-width: 500px) {
                gap: 5px;
              }
            `}
          >
            <Annotation>(주) 마일 코퍼레이션</Annotation>
            <SectionDivider />
            <Annotation>대표자: 홍진우</Annotation>
            <SectionDivider />
            <Annotation>
              주소: 서울특별시 강남구 역삼로 172, 마루 360 5F
            </Annotation>
          </div>
          <div
            css={css`
              display: flex;
              gap: 10px;
              align-items: center;
              @media screen and (max-width: 800px) {
                gap: 5px;
                flex-direction: column;
                align-items: start;
              }
            `}
          >
            <Annotation>문의: contact@mile.im</Annotation>
            {device === "web" ? <SectionDivider /> : null}
            <Annotation>사업자 번호: 633 - 88 - 02960</Annotation>
            {device === "web" ? <SectionDivider /> : null}
            <Annotation>© Mile Corp. 2023</Annotation>
          </div>
        </div>
        <div
          onClick={() => {
            gaEvent("footer-linkedin");
            window.open("https://www.linkedin.com/in/jinwoo-hong/");
          }}
          css={css`
            background-image: url("https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/linkedin.png");
            width: 45px;
            height: 45px;
            background-color: ${theme.neutral50};
            background-repeat: no-repeat;
            background-size: cover;
            margin-top: auto;
            margin-left: auto;

            @media screen and (max-width: 800px) {
              width: 24px;
              height: 24px;
            }
            cursor: pointer;
          `}
        />
      </div>
      <ChannelTalkModal
        modalIsOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
    </div>
  );
}
