/** @jsxImportSource @emotion/react */
import Header from "uis/component/header";
import { css } from "@emotion/react";
import Footer from "uis/component/footer";
import Section1 from "./section1";
import Section3 from "./section3";
import { Helmet } from "react-helmet-async";
import FreeTrialBtn from "uis/component/free-trial-btn";
import Section2 from "./section2";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";

function Home() {
    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                margin: 0 auto;
            `}
        >
            <Helmet>
                <title>마일-회의실 예약 관리, 방문자 관리 솔루션</title>
                <meta
                    name="description"
                    content="회의가 있는 모든 순간에 불편함 없이 회의실을 쓰게 해주는 마일-meeting, 방문자 경험까지 신경쓰는 가상의 리셉션 데스크 마일-visitors. 마일로 오피스 관리를 더 스마트하게! "
                />
                <meta
                    name="keywords"
                    content="mile, 마일, analytics, visitors, meeting, meeting room, 회의실, 회의실 예약, 회의실 관리, 회의실 예약관리, 회의실 대여, 서울 회의실, 강남역 회의실 대여, 회의실 관리,  공간 관리, 공간 예약, 사무실 예약, 협업 툴, 협업 솔루션, 협업 플랫폼, 일정 예약, 일정 관리, 구독 서비스, 구독형 회의실 예약 서비스, 스마트 오피스, 키오스크, 태블릿, 회의실 태블릿, 업무 관리, 공간 최적화, 캘린더 예약, 구글 캘린더, 슬랙, SaaS, slack, google calendar, 애널리틱스, 대시보드, visitors, 비지터, 방문객 관리, 방문자 관리 솔루션, 방문자 관리 서비스, 방문자 관리, 공간 데이터, 구독형 데이터 서비스"
                />
            </Helmet>
            <Header />
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Footer />
            <FreeTrialBtn />
        </div>
    );
}

export default Home;
