/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { theme } from "styles/theme";

export const CardContainer = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    border-radius: 16px;
    @media screen and (max-width: 800px) {
        gap: 8px;
        padding: 16px;
    }

    h4 {
        color: ${theme.neutral900};
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 36px */
        white-space: pre-line;

        @media screen and (max-width: 800px) {
            font-size: 16px;
        }
    }

    p {
        color: ${theme.neutral600};
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */

        @media screen and (max-width: 800px) {
            font-size: 12px;
        }
    }
`;
export const Card = ({ title, content, icon }: { title: string; content: string; icon: JSX.Element }) => {
    return (
        <CardContainer>
            <div
                css={css`
                    background-color: ${theme.neutral100};
                    border-radius: 100%;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: 800px) {
                        background-color: white;
                        width: 40px;
                        height: 40px;
                    }
                `}
            >
                {icon}
            </div>

            <h4>{title}</h4>
            <p>{content}</p>
        </CardContainer>
    );
};
