/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { BottomArrow, Card1Icon, Card2Icon, Card3Icon, Card4Icon } from "./icons";

const ArticleContainer = styled.div`
    border-radius: 24px;
    background-color: ${theme.neutral100};
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    @media screen and (max-width: 800px) {
        height: max-content;
        padding: 12px;
        border-radius: 12px;
    }
`;

const Article = styled.p`
    color: ${theme.neutral500};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%; /* 30px */

    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

const CardGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 40px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 20px;
    }
`;

const CardContainer = styled.div`
    flex: 1;
    padding: 45px 45px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 2px 2px 24px 0px rgba(69, 76, 83, 0.1);
    position: relative;
    height: 190px;

    @media screen and (max-width: 800px) {
        padding: 30px;
        border-radius: 12px;
        height: 100px;
    }
`;

const CardLabel = styled.p`
    color: ${theme.neutral900};
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 42px */

    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

const Container = styled.div`
    background-color: #f5f9fe;
    padding: 160px 0;

    @media screen and (max-width: 800px) {
        padding: 80px 0;
    }
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 1204px;
    padding: 0 118px;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
        width: calc(100% - 48px);
        padding: 0 24px;
    }
`;

const Title = styled.p`
    color: ${theme.neutral900};
    text-align: center;
    font-size: 48px;
    font-weight: 800;
    line-height: 140%; /* 67.2px */

    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`;
export default function Section5() {
    return (
        <Container>
            <SubContainer>
                <Title>
                    기존 구축형 제품의 문제들을
                    <br />
                    Cloud 기반 SaaS 솔루션으로 해결!
                </Title>

                <div
                    css={css`
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        padding: 40px 0;

                        @media screen and (max-width: 800px) {
                            padding: 20px;
                        }
                    `}
                >
                    <BottomArrow />
                </div>

                <div
                    css={css`
                        display: flex;
                        gap: 24px;
                        margin-bottom: 80px;

                        @media screen and (max-width: 800px) {
                            margin-bottom: 40px;
                            flex-direction: column;
                        }
                    `}
                >
                    <ArticleContainer>
                        <Article>
                            초기 구축에 소모되는
                            <br />
                            오랜 시간 및 비싼도입 비용
                        </Article>
                    </ArticleContainer>
                    <ArticleContainer>
                        <Article>
                            도입 이후 관리
                            <br />
                            프로세스 / 시스템의 부재
                        </Article>
                    </ArticleContainer>
                    <ArticleContainer>
                        <Article>
                            높은 유지보수 비용, 제품
                            <br />
                            자체의 낮은 유연성&자유도
                        </Article>
                    </ArticleContainer>
                    <ArticleContainer>
                        <Article>기능 중심의 레거시한 UI/UX</Article>
                    </ArticleContainer>
                </div>

                <CardGroup>
                    <CardContainer>
                        <CardLabel>
                            초기 구축에도 부담없는 빠른
                            <br />
                            도입 시간과 적은 비용
                        </CardLabel>
                        <Card1Icon />
                    </CardContainer>
                    <CardContainer>
                        <CardLabel>
                            도입 이후 운영하며 얻을 수 있는
                            <br />
                            공간 데이터 기반 인사이트
                        </CardLabel>
                        <Card2Icon />
                    </CardContainer>
                    <CardContainer>
                        <CardLabel>
                            기업별 정책에 맞는
                            <br />
                            세부 기능 커스터마이징 기능
                        </CardLabel>
                        <Card3Icon />
                    </CardContainer>
                    <CardContainer>
                        <CardLabel>
                            모던하고 사용자 친화적인
                            <br />
                            UI/UX 제공
                        </CardLabel>
                        <Card4Icon />
                    </CardContainer>
                </CardGroup>
            </SubContainer>
        </Container>
    );
}
