/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { Annotation, Btn, Container, ProductName, Title, Image } from "../styles";
import { gaEvent } from "utils/ga-utils";
import { useState } from "react";
import FreeTrialModal from "uis/free-trial";

export default function Section1() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    return (
        <Container>
            <ProductName>Mile Meeting</ProductName>
            <Title>
                회의실 사용 경험 개선과
                <br />
                운영 자동화
            </Title>
            <Annotation>
                Mile Meeting은 자동 체크인 체크아웃, 바로 예약 기능 등을 통하여 회의실 관리/운영을 자동화하며
                <br />
                직원들의 회의실 사용 경험을 개선합니다. 누적된 회의실 사용 데이터를 바탕으로 기업의 회의실 운영
                <br />
                솔루션을 제시합니다.
            </Annotation>
            <Btn
                onClick={() => {
                    gaEvent("Meeting-첫화면-무료로 시작하기");
                    setOpenModal(true);
                }}
            >
                무료로 시작하기
            </Btn>
            <Image
                src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/main.png"
                css={css`
                    margin-top: 99px;
                    width: 1150px;
                    height: 669px;
                    margin-bottom: 56px;
                `}
            />
            <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
        </Container>
    );
}
