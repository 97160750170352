/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { theme } from "styles/theme";
import Button from "uis/button";
import FreeTrialModal from "uis/free-trial";
import { gaEvent } from "utils/ga-utils";

const TagContainer = styled.div`
    border-radius: 40px;

    background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.2), -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(21px);

    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;

    padding: 12px 20px;

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

export default function Inquire({ ga }: { ga?: string }) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    return (
        <div
            css={css`
                width: 1100px;
                height: 400px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;

                background-image: url("https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/free-trial-banner.png");
                @media screen and (max-width: 800px) {
                    width: 100%;
                    margin-bottom: 80px;
                    background-position: -30px 0;
                }
            `}
        >
            <p
                css={css`
                    color: white;
                    text-shadow: 0px 4px 80px #000;
                    font-size: 32px;
                    font-weight: 800;
                    line-height: 150%;
                    margin: 80px auto 24px auto;

                    @media screen and (max-width: 800px) {
                        font-size: 24px;
                        margin: 50px auto 24px auto;
                    }
                `}
            >
                아직 고민하고 계신가요?
            </p>
            <div
                css={css`
                    display: flex;
                    gap: 40px;
                    margin: 0 219px 0 auto;

                    @media screen and (max-width: 800px) {
                        font-size: 24px;
                        flex-direction: column;
                        gap: 12px;
                        margin: 0 auto;
                    }
                `}
            >
                <TagContainer>설치부터 온보딩까지 1시간</TagContainer>
                <TagContainer>누구나 쉽게 사용 가능한 제품</TagContainer>
                <TagContainer>무료로 한 달 체험 가능</TagContainer>
            </div>
            <Button
                onClick={() => {
                    if (ga) gaEvent(ga);
                    setOpenModal(true);
                }}
                title="무료 체험하기"
                styles={{
                    container: css`
                        padding: 16px 20px;
                        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.4);
                        border-radius: 8px;
                        width: max-content;
                        margin: 64px auto 0 auto;

                        @media screen and (max-width: 800px) {
                            margin: 40px auto 0 auto;
                        }
                    `,
                    text: css`
                        font-weight: 700;
                        font-size: 16px;
                    `,
                }}
            />
            <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
        </div>
    );
}
